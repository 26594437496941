@import "https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fjalla+One&family=Lexend:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,500;1,600;1,700&display=swap";
:root {
  --color-primary: #0a2463;
  --color-light-1: #b6bdd0;
  --color-light-2: #e7e9ef;
  --color-dark-1: #081d4f;
  --color-secondary-1: #fb3640;
  --color-secondary-2: #247ba0;
  --color-secondary-3: #605f5e;
  --color-black: #020714;
  --color-neutral-dark: #484848;
  --color-neutral-grey-dark: #868483;
  --color-neutral-grey-light: #d2cfcd;
  --color-white: #f8f4f2;
  --font-regular: 1.4rem;
  --font-large: 1.6rem;
  --line-height-regular: 1.5;
  --heading-one: 3.2rem;
  --heading-two: 2.4rem;
  --heading-three: 2.1rem;
  --family-regular: "Lexend", sans-serif;
  --family-display: "Fjalla One", sans-serif;
  --transition-plain: all 0.2s;
  --transition-ease-in: all 0.3s ease-in;
}
@media only screen and (max-width: 48em) {
  :root {
    --font-regular: 1.6rem;
    --font-large: 1.8rem;
  }
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  font-size: 62.5%;
}
@media only screen and (max-width: 62.5em) {
  html {
    font-size: 56.25%;
  }
}
main {
  padding-top: 8rem;
}
body {
  font-family: var(--family-regular);
  font-size: var(--font-regular);
  font-weight: 400;
  line-height: var(--line-height-regular);
  color: var(--color-black);
}
h1,
h2 {
  font-family: var(--family-display);
  font-weight: normal;
  text-transform: uppercase;
}
.poppins--font {
  font-family: "poppins", sans-serif;
}
.heading-1 {
  font-size: 4rem;
}
.heading-2 {
  font-size: var(--heading-two);
}
.heading-3 {
  font-size: var(--heading-three);
}
.sub-heading {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
}
button,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.section--padding {
  padding: 10rem 6rem;
}
@media only screen and (max-width: 48em) {
  .section--padding {
    padding: 6rem 2rem;
  }
}
.invisible {
  opacity: 0;
  visibility: hidden;
}
.hidden {
  display: none !important;
}
.decorative-line {
  height: 1.2rem;
  width: 6rem;
  background-color: white;
  border-radius: 2rem;
  display: block;
  margin: auto;
  margin-bottom: 1rem;
}
.width-1200 {
  max-width: 114rem;
  margin: auto;
}
.width-1440 {
  max-width: 144rem;
  margin: auto;
}
.display-none {
  display: none !important;
}
.no-border-right {
  border-right: none !important;
}
.no-border-left {
  border-left: none !important;
}
@media only screen and (max-width: 28.125em) {
  .display-none--mobile {
    display: none !important;
  }
}
.padding-top--0 {
  padding-top: 0 !important;
}
.not-visible {
  opacity: 0;
  visibility: none;
  transition: all 0.25s ease-in-out;
}
@media only screen and (max-width: 28.125em) {
  .not-visible--mobile {
    opacity: 0;
    visibility: none;
    transition: all 0.25s ease-in-out;
  }
}
.link__container {
  text-decoration: none;
  color: unset;
}
.simpleslider {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn--previous,
.btn--next {
  font-family: inherit;
  top: 50%;
  position: absolute;
  font-size: 2rem;
  border: none;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 0 #00000050;
  z-index: 1000;
  opacity: 0.8;
}
.btn--previous:active,
.btn--next:active {
  opacity: 1;
}
.btn--next {
  right: 0;
  transform: translateY(-50%) translateX(-50%);
}
.btn--next:active {
  transform: translateY(-50%) translateX(-50%) scale(0.9);
}
.btn--previous {
  left: 0;
  transform: translateY(-50%) translateX(50%);
}
.btn--previous:active {
  transform: translateY(-50%) translateX(50%) scale(0.9);
}
.dots__container {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  width: 100%;
  padding: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.dots__container .dot {
  height: 1rem;
  width: 1rem;
  border-radius: 2rem;
  background-color: #979797;
  transition: var(--transition-ease-in);
}
.dots__container .dot--active {
  background-color: black;
  width: 3rem;
}
.popular-products__wrapper {
  display: flex;
  gap: 2rem;
  z-index: 1;
}
@media only screen and (max-width: 28.125em) {
  .popular-products__wrapper {
    flex-direction: column;
  }
}
.popular-products__card {
  position: relative;
  background-image: radial-gradient(#e7e9ef 50%, #b6bdd0 100%);
  background-size: contain;
  overflow: hidden;
  border-radius: 0rem;
  box-shadow: 0 2px 15px 0 #00000030;
}
.popular-products__img {
  position: relative;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popular-products__img img {
  display: block;
  width: 100%;
  height: auto;
  transition: var(--transition-ease-in);
  z-index: 1;
}
.popular-products__img::before {
  content: "";
  position: absolute;
  height: 70%;
  width: 70%;
  box-shadow: 0 0 30px 0 #00000040;
  border-radius: 50%;
  background-color: #fdfdfd;
  transition: all 0.2s ease-in;
  z-index: 0;
}
.popular-products__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #ffffff, transparent 50%);
  transition: var(--transition-ease-in);
  z-index: 5;
}
.popular-products__content {
  text-align: center;
  color: #000000;
  position: absolute;
  bottom: 2rem;
  left: 0;
  padding: 0 2rem;
  width: 100%;
  transition: var(--transition-ease-in);
  z-index: 6;
}
.popular-products__title {
  font-family: var(--family-display);
  font-size: 2.4rem;
  text-transform: uppercase;
  font-weight: 300;
  transition: var(--transition-ease-in);
}
.popular-products__button, .popular-products__button:link, .popular-products__button:visited {
  display: none;
  color: transparent;
  border: 2px solid currentColor;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding: 0 1rem;
  transition: var(--transition-ease-in);
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
}
.popular-products__card:hover .popular-products__img img {
  transform: scale(1.08) translateY(-20px);
  z-index: 10;
}
.popular-products__card:hover .popular-products__img::before {
  content: "";
  transform: scale(1.08);
  box-shadow: 0 0 30px 0 #00000040;
}
.popular-products__card:hover .popular-products__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #ffffff 20%, transparent 60%);
}
.popular-products__card:hover .popular-products__title {
  transform: translateY(5px);
}
.popular-products__card:hover .popular-products__button, .popular-products__card:hover .popular-products__button:link, .popular-products__card:hover .popular-products__button:visited {
  padding: 1rem;
  height: fit-content;
  color: var(--color-primary);
}
.popular-products__card:hover .popular-products__button:hover {
  background-color: #11005a10;
}
.product-category__wrapper {
  display: flex;
  gap: 2rem;
}
.product-category__card {
  background-color: var(--color-light-2);
  flex: 1;
  padding: 2rem;
  transform: translateY(1rem) scale(0.95);
  transition: all 0.35s, transform 0.35s ease-in;
}
.product-category__card:hover, .product-category__card:active {
  background-color: var(--color-neutral-dark);
  color: var(--color-white);
  transform: translateY(0) scale(1);
}
.product-category__card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  justify-content: space-between;
}
.product-category__icon {
  max-width: 100%;
  transform: translateY(-20%) scale(1.3);
  transition: all 0.75s cubic-bezier(0.69, -0.12, 0.37, 1.05);
}
.product-category__card:active .product-category__icon, .product-category__card:hover .product-category__icon {
  transform: translateY(0) scale(1);
}
.product-category__decorative {
  background-color: var(--color-secondary-1);
  border-radius: 50rem;
  display: block;
  height: 1rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
  transition: all 0.75s cubic-bezier(0.65, -0.73, 0.29, 1.56);
  width: 3rem;
}
.product-category__card:active .product-category__decorative, .product-category__card:hover .product-category__decorative {
  width: 7rem;
}
.product-category__button, .product-category__button:link, .product-category__button:visited {
  border: 1px solid;
  color: var(--color-primary);
  display: block;
  font-size: inherit;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
.product-category__card:active .product-category__button, .product-category__card:active .product-category__button:link, .product-category__card:active .product-category__button:visited, .product-category__card:hover .product-category__button, .product-category__card:hover .product-category__button:link, .product-category__card:hover .product-category__button:visited {
  color: var(--color-white);
}
@media only screen and (max-width: 28.125em) {
  .product-category__wrapper {
    flex-direction: column;
    gap: 4rem;
  }
}
.elite-customers {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.elite-customers__wrapper {
  max-width: 50%;
}
@media only screen and (max-width: 28.125em) {
  .elite-customers__wrapper {
    max-width: 100%;
    padding: 4rem;
  }
}
.decorative__elite {
  height: 1rem;
  width: 6rem;
  display: block;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background-color: var(--color-primary);
  border-radius: 5rem;
}
.customers__wrapper {
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.customers__icon {
  padding: 0.5rem;
  max-width: 100%;
  filter: grayscale(0) brightness(1);
  transition: all 0.25s ease-in;
}
.customers__icon:hover {
  filter: grayscale(1);
}
.customer-reviews__wrapper {
  display: flex;
  min-height: 100%;
}
.customer-review {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 100%;
  transition: var(--transition-plain);
}
.customer-review__content-wrapper {
  max-width: 60%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
@media only screen and (max-width: 28.125em) {
  .customer-review__content-wrapper {
    max-width: 100%;
    padding: 6rem;
  }
}
.customer-review__message {
  position: relative;
  font-size: 2rem;
  font-weight: 300;
}
.customer-review__message::before, .customer-review__message::after {
  content: '"';
  position: absolute;
  font-size: 8rem;
}
.customer-review__message::before {
  top: -7.5rem;
  left: -2rem;
}
.customer-review__message::after {
  bottom: -7.5rem;
  right: -2rem;
}
.customer-review__customer::before {
  content: "";
  display: block;
  position: relative;
  height: 0.8rem;
  width: 3rem;
  margin-bottom: 0.5rem;
  border-radius: 5rem;
  background-color: var(--color-secondary-1);
}
.customer-review__name {
  font-family: var(--family-display);
  font-size: 1.8rem;
}
.customer-review__about {
  font-size: 1.2rem;
}
.our-branches__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  gap: 1.5rem;
}
.our-branch {
  display: block;
}
.our-branch:not(:last-child) {
  padding-right: 1.5rem;
  border-right: 1px solid;
}
.our-branch__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.our-branch__name {
  font-family: var(--family-display);
  font-size: 2rem;
}
.our-branch__name::after {
  content: "";
  display: block;
  height: 0.8rem;
  background-color: var(--color-secondary-1);
  border-radius: 5rem;
  width: 4rem;
  margin-bottom: 0.8rem;
}
.our-branch__address {
  margin-bottom: 1rem;
  align-self: start;
}
.our-branch__call-icon {
  fill: currentColor;
  display: block;
  height: 1.8rem;
  width: 1.8rem;
}
.our-branch__phone {
  z-index: 1;
  position: relative;
  display: flex;
  gap: 1.5rem;
  padding: 1.2rem;
  border: 3rem;
  text-decoration: none;
  color: var(--color-black);
  border: 2px solid;
  justify-content: center;
  align-items: center;
  transition: all 1s ease-in-out;
}
.our-branch__phone::before {
  content: "";
  position: absolute;
  display: block;
  border-radius: 0 10rem 10rem 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #4cc705;
  transition: all 0.8s cubic-bezier(0.86, 0.06, 0.13, 0.99);
  z-index: -1;
}
.our-branch__phone:hover {
  border-color: transparent;
  color: white;
}
.our-branch__phone:hover::before {
  width: 100%;
  border-radius: 0;
}
@media only screen and (max-width: 62.5em) {
  .our-branches__wrapper {
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
  }

  .our-branch:not(:last-child) {
    padding-right: 0rem;
    border-right: 0px solid transparent;
  }
}
@media only screen and (max-width: 48em) {
  .our-branches__wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
  }
}
@media only screen and (max-width: 28.125em) {
  .our-branches__wrapper {
    grid-template-columns: repeat(1, 1fr);
    gap: 5rem;
  }
}
.our-headquarter__wrapper {
  display: flex;
  box-shadow: 0 0 25px #00000050;
  border: 5px solid #ffffff;
}
@media only screen and (max-width: 28.125em) {
  .our-headquarter__wrapper {
    flex-direction: column;
  }
}
.our-headquarter__map {
  flex: 2;
  padding: 0rem;
  height: 70vh;
}
.our-headquarter__map iframe {
  border: 0;
  width: 100%;
  height: 100%;
}
.our-headquarter__content {
  flex: 1;
  padding: 6rem;
  background-color: var(--color-light-1);
}
.our-headquarter__address {
  font-size: 1.85rem;
  font-weight: 200;
}
.our-headquarter__contact {
  padding-bottom: 6rem;
}
.our-headquarter__contact .heading-3 {
  font-family: var(--family-display);
  font-weight: 400;
  text-transform: uppercase;
}
.our-headquarter__contact .heading-3::before {
  content: "";
  display: block;
  height: 0.8rem;
  width: 4rem;
  background-color: var(--color-secondary-2);
  border-radius: 10rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.our-headquarter__send-message, .our-headquarter__send-message:link {
  position: relative;
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 1.5rem;
  text-align: center;
  color: var(--color-secondary-2);
  border: 2px solid currentColor;
  z-index: 1;
  transition: all 0.8s ease-in;
}
.our-headquarter__send-message::before, .our-headquarter__send-message:link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 10rem 10rem 0;
  height: 100%;
  width: 0;
  background-color: var(--color-secondary-2);
  z-index: -1;
  transition: all 0.8s cubic-bezier(0.86, 0.06, 0.13, 0.99);
}
.our-headquarter__send-message:hover, .our-headquarter__send-message:active {
  color: var(--color-white);
  border-color: transparent;
}
.our-headquarter__send-message:hover::before, .our-headquarter__send-message:active::before {
  width: 100%;
  border-radius: 0;
}
.social-icons__list {
  position: relative;
  list-style: none;
  display: flex;
  gap: 1rem;
  width: fit-content;
  max-width: 100%;
}
.social-icons__item a {
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-secondary-2);
  border-radius: 2px;
  transition: all 0.25s ease-in;
}
@media only screen and (max-width: 28.125em) {
  .social-icons__item a {
    height: 4.5rem;
    width: 4.5rem;
  }
}
.social-icons__icon {
  max-width: 100%;
  max-height: 100%;
  padding: 0.8rem;
  fill: #ffffff;
}
.social-icons__label {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 0.5rem;
  border-top: 1px solid var(--color-secondary-2);
  text-transform: uppercase;
  opacity: 0;
  visibility: hidden;
  transform: translateY(4rem) scaleY(0);
  transform-origin: 50% 0%;
  transition: all 0.25s ease-in;
}
.social-icons__item a:hover {
  border-radius: 50%;
  background-color: var(--color-primary);
}
.social-icons__item a:hover ~ .social-icons__label {
  transform: translateY(4rem) scaleY(1);
  opacity: 1;
  visibility: visible;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000000;
  height: 7.5rem;
  background-color: #ffffff;
  box-shadow: 0 2px 5px #00000030;
  transition: var(--transition-ease-in);
}
.header__wrapper {
  width: 80%;
  height: 100%;
  margin: auto;
  display: flex;
}
.header__logo {
  padding: 5px;
  margin-right: auto;
}
.header__logo img {
  max-height: 100%;
}
.header__items {
  display: flex;
  padding: 2rem;
  list-style: none;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
.header__item {
  padding: 2rem 0rem;
}
.header__item-a:link, .header__item-a {
  color: var(--color-black);
  padding: 0.5rem 1rem;
  color: currentColor;
  display: block;
  text-decoration: none;
  transition: var(--transition-plain);
}
.header__item-a:link::after, .header__item-a::after {
  content: "";
  display: block;
  height: 2px;
  width: 0%;
  background-color: currentColor;
  transition: var(--transition-ease-in);
}
.header__item-a:hover::after {
  width: 100%;
}
.header__products {
  transform-origin: left top;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0);
  overflow: hidden;
  list-style: none;
  position: absolute;
  display: flex;
  gap: 2rem;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 5px #00000020;
  transition: all 0.25s ease-in;
}
.header__product {
  flex: 1;
}
.header__product-a {
  padding: 0;
  text-decoration: none;
  color: currentColor;
}
.header__productrange {
  padding: 2rem;
  text-align: center;
  border: 1.5px solid transparent;
  transition: var(--transition-plain);
}
.header__productrange img {
  max-width: 90%;
}
.header__category-wrapper {
  flex: 1;
  display: flex;
  gap: 6rem;
  padding: 4rem;
}
.header__category-name {
  display: block;
  font-size: 2.2rem;
  font-weight: 900;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid;
}
.header__category-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  gap: 1rem;
}
.header__category-item a {
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  color: var(--color-primary);
  transition: all 0.25s;
}
.header__category-item a:hover {
  color: var(--color-secondary-2);
}
.header__category-item a::after {
  content: "";
  display: block;
  height: 0.2rem;
  width: 0;
  background-color: currentColor;
  transition: all 0.25s ease-in;
}
.header__category-item a:hover::after {
  width: 100%;
}
.header__featured-items {
  position: relative;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem;
}
.header__featured-card {
  position: relative;
  padding: 4rem;
  flex: 1;
  z-index: 10;
}
.header__featured-card::before {
  content: "Featured";
  padding: 1rem 2rem;
  position: absolute;
  z-index: 0;
  color: white;
  background-color: var(--color-primary);
  top: 0;
  left: 0;
  border-radius: 0px 0px 20px 0px;
}
.header__featured-items .header__featured-card {
  text-decoration: none;
  color: currentColor;
  box-shadow: 0 0 1px 0px #0a2463, 0px 0px 20px #a8aebc38, inset 0px 0px 2px 0px #ced8ff82;
}
.header__featured-img {
  max-width: 100%;
  position: relative;
  transition: all 0.25s ease-in;
}
.header__featured-title {
  position: relative;
  display: block;
  width: 100%;
  font-size: 2rem;
  text-align: center;
}
.header__featured-card:hover {
  box-shadow: none;
}
.header__featured-card:hover .header__featured-items .header__featured-card {
  box-shadow: none;
}
.header__featured-card:hover .header__featured-img {
  transform: translateY(-15%) scale(1.2);
}
.header__productrange-label {
  position: relative;
  display: block;
  margin: auto;
  width: fit-content;
  padding: 1rem;
}
.header__productrange-label::after {
  content: "";
  display: block;
  height: 1.5px;
  width: 0%;
  background-color: currentColor;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.header__item--product:hover .header__products {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  overflow: visible;
}
.header__product-a:hover .header__productrange {
  transform: scale(0.95);
  background-color: #f8f8f8;
}
.header__product-a:hover .header__productrange-label {
  color: var(--color-secondary-1);
}
.header__product-a:hover .header__productrange-label::after {
  width: 100%;
  background-color: currentColor;
}
.header--hidden {
  transform: translateY(-110%);
}
.mob-header__wrapper {
  max-width: 144rem;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.2rem;
}
.mob-header__logo img {
  max-width: 16.4rem;
}
.mob-header__menu-btn {
  position: relative;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  background-color: transparent;
  border-radius: 0.5rem;
  z-index: 100005;
}
.mob-header__menu-btn--line-1, .mob-header__menu-btn--line-2, .mob-header__menu-btn--line-3 {
  border-radius: 100px;
  border: none;
  width: 3rem;
  height: 0.4rem;
  background-color: black;
  transform-origin: center;
  transition: transform 0.2s;
}
.mob-header__nav {
  position: fixed;
  right: 0;
  background-color: #ffffff;
  width: 85%;
  height: 100vh;
  z-index: 999999;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  padding-top: 8rem;
  transform: translateX(100%);
  box-shadow: -1px 0 4px #00000020;
  transition: all 0.25s ease-in-out;
}
.mob-header__nav a {
  text-decoration: none;
  color: #000000;
  z-index: 2;
  background-color: #ffffff;
}
.mob-header__nav ul {
  list-style: none;
  font-size: 1.8rem;
  padding-left: 1rem;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform-origin: top;
  transform: translateY(-100%) scaleY(0.2);
  z-index: 0;
}
.mob-header__links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.mob-header__a {
  position: relative;
  transition: all 0.25s ease-in;
}
.mob-header__a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: currentColor;
  transition: width 0.25s ease-in;
}
.mob-header__a:hover {
  color: var(--color-secondary-2);
}
.mob-header__a:hover::after {
  width: 100%;
}
.mob-header__category {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.mob-header__link-text {
  flex: 1;
}
.mob-header__arrow {
  display: inline-block;
  font-family: monospace;
  padding: 0px 10px;
  transition: transform 0.1s ease-in-out;
}
.expand-list--mobile .mob-header__arrow {
  transform: rotate(-90deg);
}
.expand-list--mobile .mob-header__catgory-list {
  visibility: visible;
  opacity: 1;
  position: relative;
  transform: scale(1);
  transition: transform 0.15s ease-in, opacity 0.25s ease-in;
}
.header--active .mob-header__menu-btn--line-1 {
  transform: translateY(0.9rem) rotate(135deg);
}
.header--active .mob-header__menu-btn--line-2 {
  opacity: 0;
}
.header--active .mob-header__menu-btn--line-3 {
  transform: translateY(-0.9rem) rotate(-135deg);
}
.header--active .mob-header__nav {
  transform: translateX(0);
}
.cover-template-1__wrapper {
  padding: 12rem 4rem;
}
.cover-template-1__wrapper * {
  display: block;
}
@media only screen and (max-width: 48em) {
  .cover-template-1__wrapper {
    padding: 6rem 0;
  }
}
.cover-template-1__heading {
  font-size: 5rem;
  line-height: 1;
}
.cover-template-1__main-head {
  font-size: 8rem;
}
.product-list__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
  gap: 2.5rem;
}
.product-card {
  background-color: #f6f6f6;
  box-shadow: 0px 2px 5px #00000020;
  transition: all 0.25s ease-in;
}
.product-card__image img {
  padding-top: 2rem;
  display: block;
  max-width: 100%;
  transition: all 0.25s ease-in;
}
.product-card__info {
  padding: 3.5rem;
}
.product-card__title {
  font-size: 2.4rem;
  text-transform: capitalize;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.product-card__feature {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--color-secondary-2);
  margin-bottom: 0.5rem;
}
.product-card__button {
  font-size: 1.6rem;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  color: currentColor;
  text-decoration: none;
}
.product-card__button::after {
  content: "";
  display: block;
  background-color: currentColor;
  width: 0;
  height: 2px;
  transition: width 0.25s ease-in;
}
.product-card:hover {
  box-shadow: 0px 4px 15px #00000020;
}
.product-card:hover .product-card__image img {
  transform: scale(1.1);
}
.product-card:hover .product-card__button {
  color: var(--color-secondary-2);
}
.product-card:hover .product-card__button::after {
  width: 100%;
}
.inquiry-form__container {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  display: flex;
  flex-direction: column;
  height: 85vh;
  width: 45%;
  background-color: white;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px 0 #00000020;
  transition: all 0.3s ease-in-out;
  z-index: 10000;
}
@media only screen and (max-width: 48em) {
  .inquiry-form__container {
    padding: 8rem 4rem;
    width: 93%;
    justify-content: flex-start;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    bottom: 15vh;
    height: 70vh;
    overflow: scroll;
  }
}
@media only screen and (max-width: 28.125em) {
  .inquiry-form__container {
    padding: 2rem;
    height: auto;
    bottom: 5vh;
    max-height: 80vh;
    box-shadow: 0px -6px 10px 0 #00000020;
  }
}
.inquiry-form__collaps {
  bottom: 2rem;
  left: 90%;
  height: 2rem;
  width: 1rem;
  opacity: 0;
}
@media only screen and (max-width: 28.125em) {
  .inquiry-form__collaps {
    width: 90%;
    left: 50%;
  }
}
.close-inquiry-form {
  position: absolute;
  cursor: pointer;
  right: 2rem;
  top: 2rem;
  height: 4rem;
  width: 4rem;
  color: var(--color-primary);
  border: 1px solid currentColor;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in;
}
.close-inquiry-form:hover {
  border-radius: 20rem;
  color: var(--color-white);
  border-color: var(--color-secondary-1);
  background-color: var(--color-secondary-1);
}
.close-inquiry-form:active {
  transform: scale(0.95);
}
.form-1 {
  padding: 4rem;
  width: 100%;
}
.form-1__field--half {
  display: inline-block;
  width: 46%;
  margin-bottom: 2rem;
  margin-right: 3%;
}
@media only screen and (max-width: 28.125em) {
  .form-1__field--half {
    width: 100%;
  }
}
.form-1__field--full {
  display: block;
  margin-bottom: 2rem;
  margin-right: 3%;
}
.form-1__label {
  display: block;
}
.form-1__text, .form-1__dropdown {
  display: block;
  padding: 1rem;
  width: 100%;
  border: none;
  border-bottom: 2px solid;
}
.form-1__text:focus, .form-1__dropdown:focus {
  border: none;
  border-bottom: 2px solid var(--color-secondary-2);
  outline: none;
}
.form-1 textarea {
  font-family: var(--family-regular);
  line-height: 1.5;
}
.form-1__btn {
  position: relative;
  color: #ffffff;
  background-color: var(--color-primary);
  border: none;
  padding: 1.5rem;
  width: 100%;
  transition: all 0.25s ease-in;
}
.form-1__btn:hover {
  background-color: var(--color-secondary-2);
}
.h-captcha {
  max-width: 100%;
}
.h-captcha iframe {
  max-width: 100%;
}
@media only screen and (max-width: 28.125em) {
  .h-captcha iframe {
    width: 100%;
  }
}
#view__result {
  margin-top: 1rem;
  padding: 1rem 4rem;
  display: none;
}
.form-success {
  display: block !important;
  background-color: #c1f0bd;
}
.form-fail {
  display: block !important;
  background-color: #f5cbcb;
}
.form-caution {
  display: block !important;
  background-color: #f3f3c5;
}
.section__top-category {
  padding: 6rem 4rem;
}
.section__top-category h2 {
  padding: 2rem 0rem;
}
@media only screen and (max-width: 48em) {
  .section__top-category {
    padding: 2rem;
  }
  .section__top-category h2 {
    padding: 2rem 0rem;
  }
}
.top-category__wrapper {
  display: flex;
  gap: 2rem;
}
@media only screen and (max-width: 28.125em) {
  .top-category__wrapper {
    flex-direction: column;
    gap: 4rem;
  }
}
.top-category__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  box-shadow: 0px 0px 0px 1px #eaeaea;
  transition: all 0.25s ease-in;
  text-decoration: none;
  color: var(--color-black);
}
.top-category__img {
  flex: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1.15);
  transition: all 0.25s ease-in;
}
.top-category__img img {
  max-width: 100%;
}
.top-category__content {
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.top-category__title {
  font-family: var(--family-display);
  font-size: 2rem;
  text-transform: uppercase;
  flex: block;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-in;
}
.top-category__content::before {
  content: "";
  height: 0.8rem;
  width: 4rem;
  background-color: var(--color-secondary-2);
  border-radius: 5rem;
  margin-bottom: 1rem;
  transition: all 0.25s ease-in;
}
.top-category__card:hover,
.top-category__card:active {
  box-shadow: 0px 10px 20px -7px #00000022;
}
.top-category__card:hover .top-category__img,
.top-category__card:active .top-category__img {
  transform: translateY(-6%) scale(1.2);
}
.top-category__card:hover .top-category__content::before,
.top-category__card:active .top-category__content::before {
  width: 7rem;
  background-color: var(--color-secondary-1);
}
.top-category__card:hover .top-category__title,
.top-category__card:active .top-category__title {
  transform: translateY(0.3rem);
}
.main-content--home {
  max-width: 100%;
  overflow-x: hidden;
}
.hero__slider-wrapper {
  overflow: hidden;
}
.hero__slider {
  width: 100%;
  gap: 0;
}
.hero__slide {
  flex: 0 0 100%;
  height: 100vh;
  transition: all 0.3s ease-out;
  border: 0rem solid white;
  background-size: cover;
  position: relative;
}
.hero-slide__container {
  position: absolute;
  bottom: 10%;
  width: 80rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.hero-slide__subheading {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: bold;
}
@media only screen and (max-width: 48em) {
  .hero-slide__container {
    width: 100%;
  }
}
@media only screen and (max-width: 28.125em) {
  .hero__slide {
    height: 65vh;
    width: 100%;
  }

  .hero-slide__container {
    padding: 4rem;
  }
}
.slide-1 {
  color: var(--color-white);
  background-image: linear-gradient(to top, #0a0253 10%, transparent 60%), url("slider-image-handles.6048a90e.webp");
  background-position: top center;
}
.slide-2 {
  color: var(--color-white);
  background-image: linear-gradient(to top, #582f15 10%, #00000040 50%, transparent 90%), url("finishes-background-2.5db4c143.webp");
}
.slide-3 {
  color: var(--color-white);
  background-image: linear-gradient(to top, #081f5e 10%, #00000040 50%, transparent 90%), url("distribution-background.287626c9.webp");
}
.slide-4 {
  color: var(--color-white);
  background-image: linear-gradient(to top, #6b0303 10%, #00000040 50%, transparent 90%), url("manufacturing-1.d766c952.webp");
}
.hero-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.section__popular-products h2 {
  margin-bottom: 2rem;
}
.section__popular-category {
  padding-top: 1rem !important;
}
.section__our-customers {
  padding: 0rem 6rem;
}
@media only screen and (max-width: 48em) {
  .section__our-customers {
    padding: 0rem 4rem;
  }
}
@media only screen and (max-width: 28.125em) {
  .section__our-customers {
    padding: 0rem 2rem;
  }
}
.our-customers__container {
  display: flex;
  padding: 0;
  min-height: 80vh;
  border-radius: 0rem;
  overflow: hidden;
}
@media only screen and (max-width: 48em) {
  .our-customers__container {
    min-height: auto;
  }
}
@media only screen and (max-width: 28.125em) {
  .our-customers__container {
    flex-direction: column;
  }
}
.our-customers__col-1 {
  flex: 3;
  background-color: var(--color-light-2);
}
.our-customers__col-2 {
  flex: 2;
  background-color: var(--color-light-1);
}
.section__our-branches {
  background-color: var(--color-light-2);
}
.section__our-branches h2 {
  margin-bottom: 2rem;
}
@media only screen and (max-width: 28.125em) {
  .section__our-branches {
    padding: 6rem 4rem;
  }
}
.section__out-headquarter {
  background-color: var(--color-light-2);
  padding: 6rem;
}
@media only screen and (max-width: 28.125em) {
  .section__out-headquarter {
    padding: 2rem;
  }
}
.section__out-headquarter .heading-2 {
  margin-bottom: 1rem;
}
.section__out-headquarter .heading-2::before {
  content: "";
  display: block;
  height: 0.8rem;
  width: 4rem;
  border-radius: 10rem;
  margin-bottom: 0.5rem;
  background-color: var(--color-secondary-1);
}
.categories__content {
  padding: 4rem;
  padding-top: 8rem;
}
@media only screen and (max-width: 48em) {
  .categories__content {
    padding: 2rem;
    padding-top: 8rem;
  }
}
.category__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
  gap: 2rem;
}
.category-card {
  background-color: #f6f6f6;
  box-shadow: 0 3px 6px 1px #00000020;
}
.category-card__image {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 5/4;
}
.category-card__image img {
  width: 100%;
  height: 25rem;
  object-fit: cover;
}
.category-card__info {
  padding: 0.5rem 4rem 4rem 4rem;
}
.category-card__title {
  font-size: 2.4rem;
}
.category-card__view-more {
  display: inline-block;
  position: relative;
  font-size: 1.8rem;
  text-decoration: none;
  color: var(--color-secondary-1);
}
.category-card__view-more::after {
  content: "";
  position: absolute;
  background-color: currentColor;
  height: 0.2rem;
  width: 0;
  left: 0;
  bottom: 0;
  transition: width 0.25s ease-in;
}
.category-card:hover .category-card__view-more {
  color: var(--color-secondary-2);
}
.category-card:hover .category-card__view-more::after {
  width: 100%;
}
.category-card a {
  text-decoration: none;
  color: var(--color-black);
}
@media only screen and (max-width: 48em) {
  .category-page__sub-head {
    font-size: 2.4rem;
    font-weight: 700;
    color: var(--color-secondary-2);
    display: block;
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 48em) {
  .category-page__main-head {
    font-size: 5rem;
    display: block;
  }
}
.product-page {
  padding-top: 7.5rem;
  display: flex;
}
@media only screen and (max-width: 48em) {
  .product-page {
    flex-direction: column;
    font-size: 2rem;
  }
}
.product-display {
  position: relative;
  padding: 2rem;
  flex: 1;
}
.product-display__img {
  position: sticky;
  max-width: 100%;
  width: 100%;
  top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 48em) {
  .product-display__img {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: auto;
    padding-top: 0;
    top: 0;
  }
}
.product-display__img img {
  width: 45rem;
  height: 45rem;
  max-width: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 48em) {
  .product-display__img img {
    max-width: 100%;
  }
}
.product-content {
  flex: 1;
  padding: 6rem;
}
@media only screen and (max-width: 28.125em) {
  .product-content {
    padding: 2rem;
  }
}
/***** Content Area  ********/
.product-content__material {
  display: flex;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.product-content__material::after {
  content: "";
  flex: 1;
  height: 0.05rem;
  width: 100%;
  background-color: black;
}
.product-content__title {
  font-size: 4rem;
}
.product-content__description {
  font-size: 1.8rem;
  color: var(--color-neutral-dark);
  margin-bottom: 1rem;
}
.product-variant__options {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}
.product-variant__info {
  margin-bottom: 0.5rem;
}
.product-variant__item {
  display: flex;
}
.product-variant__radio {
  display: none;
}
.product-variant__label {
  cursor: pointer;
  position: relative;
  padding: 1.25rem;
  z-index: 100;
  width: 100%;
  transition: all 0.25s ease-in;
}
.product-variant__label::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  z-index: -1;
  width: 100%;
  background-color: var(--color-primary);
  transition: all 0.25s ease-in;
}
.product-variant__radio:checked ~ .product-variant__label {
  color: var(--color-white);
}
.product-variant__radio:checked ~ .product-variant__label::after {
  height: 100%;
}
.product-variant__view-all {
  position: relative;
  display: flex;
  padding-bottom: 2px;
  margin-bottom: 2rem;
  font-size: 2rem;
  border: none;
  background-color: transparent;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
}
.product-variant__view-all::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 0;
  height: 2px;
  background-color: currentColor;
  transition: all 0.25s ease-in;
}
.product-variant__view-all:hover {
  color: var(--color-secondary-1);
}
.product-variant__view-all:hover::after {
  width: 100%;
}
.variant__info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 2rem;
  opacity: 0;
  transform: scale(0.95) translateY(10px);
  transform-origin: 0% 0% 0;
}
.variant__field {
  margin-bottom: 1rem;
}
.variant__field-label, .variant__field-text {
  display: block;
}
.variant__field-label {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
}
@media only screen and (max-width: 48em) {
  .variant__field-label {
    font-size: 1.6rem;
  }
}
.variant__field-text {
  display: block;
}
@media only screen and (max-width: 48em) {
  .variant__field-text {
    font-size: 2.4rem;
  }
}
.variant__options-list {
  list-style: none;
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;
}
.variant__options-item {
  padding: 5px 10px;
  margin-top: 1rem;
  border: 2px solid var(--color-neutral-dark);
}
.variant__op-item-label {
  font-weight: 600;
}
.variant__op-item-currency {
  color: var(--color-secondary-1);
}
.finishes__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem 0rem;
}
.finish__option {
  border: 1px solid #ebebeb;
  display: flex;
}
.finish__thumbnail {
  width: 6.4rem;
  max-width: 100%;
}
.finish__text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0.5rem 1rem;
  line-height: 1;
}
.finish__label {
  font-size: 1.2rem;
}
.finish__rate {
  font-size: 2rem;
}
.contact-options {
  position: fixed;
  display: flex;
  padding: 1.5rem;
  bottom: 0px;
  right: 0px;
  gap: 1rem;
  z-index: 10005;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 28.125em) {
  .contact-options {
    width: 100%;
    bottom: 0;
    right: 0;
  }
}
.send-inquiry-btn {
  border: 1px solid currentColor;
  font-size: 1.6rem;
  padding: 1.5rem 3rem;
  background-color: var(--color-black);
  color: var(--color-white);
  transition: all 0.25s ease-in;
}
.send-message-btn {
  height: 4.6rem;
  width: 4.6rem;
  border: 1px solid var(--color-black);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: all 0.25s ease-in;
}
.send-message-btn svg {
  fill: var(--color-black);
  max-height: 3rem;
  max-width: 3rem;
}
.send-message-btn:hover {
  border-radius: 10rem;
  background-color: var(--color-secondary-2);
  border-color: var(--color-secondary-2);
}
.send-message-btn:hover svg {
  fill: var(--color-white);
}
.send-inquiry-btn:hover {
  background-color: var(--color-secondary-2);
  border-radius: 60px;
}
@media only screen and (max-width: 28.125em) {
  .send-inquiry {
    flex: 1;
  }
}
.dramatic-entry {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: all 0.15s ease-in;
}
.list__content {
  padding: 4rem;
  padding-top: 8rem;
}
@media only screen and (max-width: 48em) {
  .list__content {
    padding: 2rem;
    padding-top: 8rem;
  }
}
@media only screen and (max-width: 48em) {
  .list-page__sub-head {
    font-size: 2.4rem;
    color: var(--color-secondary-1);
  }
}
@media only screen and (max-width: 48em) {
  .list-page__main-head {
    font-size: 5rem;
  }
}
.text__list {
  padding: 40px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.to__copy {
  border: 1px solid;
  font-size: 24px;
  padding: 10px;
}
.copied {
  background-color: yellow;
}
.about-us__main {
  font-size: 1.6rem;
}
.about-us__intro {
  padding: 2rem 6rem;
}
.about-us__vision {
  padding: 2rem 6rem;
}
.about-us__vision h3 {
  color: var(--color-secondary-2);
}
.about-us__vision-wrapper {
  padding: 4rem;
  border: 1.5px solid var(--color-secondary-2);
}
.about-us__customers {
  padding-bottom: 4rem;
}
.broucher__main {
  max-width: 120rem;
  margin: auto;
  padding: 4rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.broucher__wrapper {
  display: flex;
  gap: 1rem;
}
.broucher__card {
  transform: scale(0.9);
  transition: all 0.25s ease-in;
}
.broucher__card img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  filter: grayscale(1);
  transition: all 0.25s ease-in;
}
.broucher__card:hover, .broucher__card:active {
  transform: scale(1);
  box-shadow: 0px 4px 8px -2px #00000043;
}
.broucher__card:hover img, .broucher__card:active img {
  filter: grayscale(0);
}
@media only screen and (max-width: 28.125em) {
  .broucher__wrapper {
    flex-direction: column;
  }
  .broucher__card {
    transform: scale(1);
  }
  .broucher__card img {
    filter: grayscale(0);
  }
}
.contact-form__container {
  padding-top: 4rem;
  background-image: linear-gradient(to bottom, transparent 50%, var(--color-light-2) 50%);
}
.contact-form__wrapper {
  margin: auto;
  max-width: 80rem;
  background-color: #ffffff;
}
.footer {
  background-color: var(--color-light-1);
  display: flex;
  gap: 3rem;
  font-size: 1.6rem;
  padding: 8rem 4rem;
}
@media only screen and (max-width: 28.125em) {
  .footer {
    flex-direction: column;
    padding: 6rem 4rem;
    font-size: 2rem;
  }
}
.footer__col--1 {
  flex: 3;
}
.footer__col--2 {
  flex: 2;
}
.footer__col--3 {
  flex: 2;
}
.footer__col--4 {
  flex: 3;
}
.footer__logo {
  width: 24rem;
  max-width: 100%;
}
.footer ul {
  list-style: none;
}
.footer__links-block {
  margin-bottom: 1rem;
}
.footer__block-title {
  font-family: var(--family-display);
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.footer__link--a {
  text-decoration: none;
  color: var(--color-black);
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.footer__link--a::after {
  content: ">";
  text-decoration: none;
  font-family: monospace;
  color: currentColor;
  transform: scale(0) translateX(-1.5rem);
  opacity: 1;
  transition: transform 0.25s ease-in, opacity 0.25s 0.5s;
}
.footer__link--a:hover {
  color: var(--color-primary);
}
.footer__link--a:hover::after {
  transform: scale(1) translateX(0);
}
.footer__headquarter {
  background-color: transparent;
  padding: 0;
}
.footer__copywrite {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-primary);
}
.footer__basic-contact {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.footer__basic-contact a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--color-black);
  gap: 1rem;
}
.footer__basic-contact a:hover {
  color: var(--color-primary);
}
.footer__basic-contact svg {
  fill: currentColor;
  height: 1.8rem;
  width: 1.8rem;
}
.related-products {
  max-width: 140rem;
  margin: auto;
  padding: 2rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.related-products__wrapper {
  display: flex;
  gap: 2rem;
}
@media only screen and (max-width: 28.125em) {
  .related-products__wrapper {
    flex-direction: column;
  }
}
/*# sourceMappingURL=index.cfd4667f.css.map */
