.section__top-category {
  padding: 6rem 4rem;
  & h2 {
    padding: 2rem 0rem;
  }

  @include change(tablet-small) {
    padding: 2rem;
    & h2 {
      padding: 2rem 0rem;
    }
  }
}

.top-category {
  &__wrapper {
    display: flex;
    gap: 2rem;
    @include change(mobile-regular) {
      flex-direction: column;
      gap: 4rem;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    box-shadow: 0px 0px 0px 1px #eaeaea;
    transition: all 0.25s ease-in;
    text-decoration: none;
    color: var(--color-black);
  }

  &__img {
    flex: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.15);
    transition: all 0.25s ease-in;
    & img {
      max-width: 100%;
    }
  }
  &__content {
    flex: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__title {
    font-family: var(--family-display);
    font-size: 2rem;
    text-transform: uppercase;
    flex: block;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease-in;
  }
  &__content::before {
    content: "";
    height: 0.8rem;
    width: 4rem;
    background-color: var(--color-secondary-2);
    border-radius: 5rem;
    margin-bottom: 1rem;
    transition: all 0.25s ease-in;
  }
}

.top-category__card:hover,
.top-category__card:active {
  box-shadow: 0px 10px 20px -7px #00000022;
  .top-category {
    &__img {
      transform: translateY(-6%) scale(1.2);
      & img {
      }
    }
    &__content {
    }
    &__content::before {
      width: 7rem;
      background-color: var(--color-secondary-1);
    }
    &__title {
      transform: translateY(0.3rem);
    }
  }
}
