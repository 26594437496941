.elite-customers {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &__wrapper {
    max-width: 50%;
    @include change(mobile-regular) {
      max-width: 100%;
      padding: 4rem;
    }
  }
}

.decorative__elite {
  height: 1rem;
  width: 6rem;
  display: block;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background-color: var(--color-primary);
  border-radius: 5rem;
}
.customers {
  &__wrapper {
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  &__icon {
    padding: 0.5rem;
    max-width: 100%;
    filter: grayscale(0) brightness(1);
    transition: all 0.25s ease-in;
  }
  &__icon:hover {
    filter: grayscale(1);
  }
}
