.footer {
  background-color: var(--color-light-1);
  display: flex;
  gap: 3rem;
  font-size: 1.6rem;
  padding: 8rem 4rem;

  @include change(mobile-regular) {
    flex-direction: column;
    padding: 6rem 4rem;
    font-size: 2rem;
  }
  &__col {
    &--1 {
      flex: 3;
    }
    &--2 {
      flex: 2;
    }
    &--3 {
      flex: 2;
    }
    &--4 {
      flex: 3;
    }
  }
  &__logo {
    width: 24rem;
    max-width: 100%;
    // filter: brightness(100);
  }
  & ul {
    list-style: none;
  }
  &__links-block {
    margin-bottom: 1rem;
  }
  &__block-title {
    font-family: var(--family-display);
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
  &__link--a {
    text-decoration: none;
    color: var(--color-black);
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  &__link--a::after {
    content: ">";
    text-decoration: none;
    font-family: monospace;
    color: currentColor;
    transform: scale(0) translateX(-1.5rem);
    opacity: 1;
    transition: transform 0.25s ease-in, opacity 0.25s 0.5s;
  }
  &__link--a:hover {
    // text-decoration: underline;
    color: var(--color-primary);
  }
  &__link--a:hover::after {
    transform: scale(1) translateX(0);
  }
  & * {
    // color: #ffffff;
  }
  &__headquarter {
    background-color: transparent;
    padding: 0;
  }
  &__copywrite {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--color-white);
    background-color: var(--color-primary);
  }
  &__basic-contact {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    & a {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: var(--color-black);
      gap: 1rem;
    }
    & a:hover {
      color: var(--color-primary);
    }
    & svg {
      fill: currentColor;
      height: 1.8rem;
      width: 1.8rem;
    }
  }
}
