.about-us {
  &__main {
    font-size: 1.6rem;
  }
  &__intro {
    // max-width: 120rem;
    padding: 2rem 6rem;
  }
  &__vision {
    padding: 2rem 6rem;
    & h3 {
      color: var(--color-secondary-2);
    }
    &-wrapper {
      padding: 4rem;
      border: 1.5px solid var(--color-secondary-2);
    }
  }
  &__customers {
    padding-bottom: 4rem;
  }
}
