.our-branches__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  gap: 1.5rem;
}

.our-branch {
  display: block;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex: 1;
  &:not(:last-child) {
    padding-right: 1.5rem;
    border-right: 1px solid;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__name {
    font-family: var(--family-display);
    font-size: 2rem;
    &::after {
      content: "";
      display: block;
      height: 0.8rem;
      background-color: var(--color-secondary-1);
      border-radius: 5rem;
      width: 4rem;
      margin-bottom: 0.8rem;
    }
  }
  &__address {
    margin-bottom: 1rem;
    align-self: start;
  }
  &__contact {
  }
  &__call-icon {
    fill: currentColor;
    display: block;
    height: 1.8rem;
    width: 1.8rem;
  }
  &__phone {
    z-index: 1;
    position: relative;
    display: flex;
    gap: 1.5rem;
    padding: 1.2rem;
    border: 3rem;
    text-decoration: none;
    color: var(--color-black);
    // border-radius: 10rem;
    border: 2px solid;
    justify-content: center;
    align-items: center;
    transition: all 1s ease-in-out;
    &::before {
      content: "";
      position: absolute;
      display: block;
      border-radius: 0 10rem 10rem 0;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: rgb(76, 199, 5);
      transition: all 0.8s cubic-bezier(0.86, 0.06, 0.13, 0.99);
      z-index: -1;
    }
    &:hover {
      border-color: transparent;
      color: white;
      &::before {
        width: 100%;
        border-radius: 0;
      }
    }
  }
}

@include change(desktop-small) {
  .our-branches__wrapper {
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
  }

  .our-branch {
    &:not(:last-child) {
      padding-right: 0rem;
      border-right: 0px solid transparent;
    }
  }
}

@include change(tablet-small) {
  .our-branches__wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
  }
}

@include change(mobile-regular) {
  .our-branches__wrapper {
    grid-template-columns: repeat(1, 1fr);
    gap: 5rem;
  }
}
