.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000000;
  height: 7.5rem;
  background-color: #ffffff;
  box-shadow: 0 2px 5px #00000030;
  transition: var(--transition-ease-in);
  &__wrapper {
    width: 80%;
    height: 100%;
    margin: auto;
    display: flex;
  }
  &__logo {
    padding: 5px;
    margin-right: auto;
    & img {
      max-height: 100%;
    }
  }
  &__items {
    display: flex;
    padding: 2rem;
    list-style: none;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  &__item {
    padding: 2rem 0rem;
    &-a:link,
    &-a {
      color: var(--color-black);
      padding: 0.5rem 1rem;
      color: currentColor;
      display: block;
      text-decoration: none;
      transition: var(--transition-plain);
      &::after {
        content: "";
        display: block;
        height: 2px;
        width: 0%;
        background-color: currentColor;
        transition: var(--transition-ease-in);
      }
    }
  }
  &__item-a:hover {
    &::after {
      width: 100%;
    }
  }
  &__products {
    transform-origin: left top;
    opacity: 0;
    visibility: hidden;
    transform: translateY(0);
    overflow: hidden;
    list-style: none;
    position: absolute;
    display: flex;
    gap: 2rem;
    // padding: 4rem;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 5px #00000020;
    transition: all 0.25s ease-in;
  }

  &__product {
    flex: 1;
  }
  &__product-a {
    padding: 0;
    text-decoration: none;
    color: currentColor;
  }
  &__productrange {
    padding: 2rem;
    text-align: center;
    border: 1.5px solid transparent;
    transition: var(--transition-plain);

    & img {
      max-width: 90%;
    }
  }
  &__category-wrapper {
    flex: 1;
    display: flex;
    gap: 6rem;
    padding: 4rem;
    // background-color: #b6bdd0;
  }
  &__product-category {
  }
  &__category-name {
    display: block;
    font-size: 2.2rem;
    font-weight: 900;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid;
  }
  &__category-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    gap: 1rem;
  }
  &__category-item a {
    display: flex;
    flex-direction: column;
    position: relative;
    text-decoration: none;
    color: var(--color-primary);
    transition: all 0.25s;
  }
  &__category-item a:hover {
    color: var(--color-secondary-2);
  }
  &__category-item a::after {
    content: "";
    display: block;
    height: 0.2rem;
    width: 0;
    background-color: currentColor;
    transition: all 0.25s ease-in;
  }
  &__category-item a:hover::after {
    width: 100%;
  }
  &__featured-items {
    position: relative;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 2rem;
  }
  &__featured-card {
    position: relative;
    padding: 4rem;
    flex: 1;
    // background-image: radial-gradient(#e7e9ef 50%, #b6bdd0 100%);
    z-index: 10;
    &::before {
      content: "Featured";
      padding: 1rem 2rem;
      position: absolute;
      z-index: 0;
      color: white;
      background-color: var(--color-primary);
      top: 0;
      left: 0;
      border-radius: 0px 0px 20px 0px;
    }
  }

  &__featured-items .header__featured-card {
    text-decoration: none;
    color: currentColor;
    box-shadow: 0 0 1px 0px #0a2463, 0px 0px 20px #a8aebc38,
      inset 0px 0px 2px 0px #ced8ff82;
  }

  &__featured-img {
    max-width: 100%;
    position: relative;
    // transform: translate(-50%, -50%);
    transition: all 0.25s ease-in;
  }

  &__featured-title {
    position: relative;
    // font-family: var(--family-display);
    display: block;
    width: 100%;
    font-size: 2rem;
    text-align: center;
  }
  &__featured-card:hover {
    box-shadow: none;
    .header {
      &__featured-item {
      }
      &__featured-items .header__featured-card {
        box-shadow: none;
      }
      &__featured-img {
        transform: translateY(-15%) scale(1.2);
      }
    }
  }
  &__productrange-label {
    position: relative;
    display: block;
    margin: auto;
    width: fit-content;
    padding: 1rem;
    &::after {
      content: "";
      display: block;
      height: 1.5px;
      width: 0%;
      background-color: currentColor;
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}

.header__item--product:hover {
  .header__products {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    overflow: visible;
  }
}

.header__product-a:hover {
  .header {
    &__productrange {
      transform: scale(0.95);
      background-color: #f8f8f8;
    }
    &__productrange-label {
      color: var(--color-secondary-1);
      &::after {
        width: 100%;
        background-color: currentColor;
      }
    }
  }
}

.header--hidden {
  transform: translateY(-110%);
}

.mob-header {
  &__wrapper {
    max-width: 144rem;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1.2rem;
  }
  &__logo img {
    max-width: 16.4rem;
  }
  &__menu-btn {
    position: relative;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    background-color: transparent;
    border-radius: 0.5rem;
    z-index: 100005;
    &--line-1,
    &--line-2,
    &--line-3 {
      border-radius: 100px;
      border: none;
      width: 3rem;
      height: 0.4rem;
      background-color: black;
      transform-origin: center;
      transition: transform 0.2s;
    }
  }

  &__nav {
    position: fixed;
    right: 0;
    background-color: #ffffff;
    width: 85%;
    height: 100vh;
    z-index: 999999;
    // padding-top: 9.5rem;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    // align-items: center;
    padding-top: 8rem;
    transform: translateX(100%);
    box-shadow: -1px 0 4px #00000020;
    transition: all 0.25s ease-in-out;
  }
  &__nav a {
    text-decoration: none;
    color: #000000;
    z-index: 2;
    background-color: #ffffff;
  }
  &__nav ul {
    list-style: none;
    font-size: 1.8rem;
    padding-left: 1rem;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transform-origin: top;
    transform: translateY(-100%) scaleY(0.2);

    z-index: 0;
  }
  &__links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__a {
    position: relative;
    transition: all 0.25s ease-in;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 0;
      background-color: currentColor;
      transition: width 0.25s ease-in;
    }
  }
  &__a:hover {
    color: var(--color-secondary-2);
  }
  &__a:hover::after {
    width: 100%;
  }
  &__category {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__link-text {
    flex: 1;
  }
  &__arrow {
    display: inline-block;
    font-family: monospace;
    padding: 0px 10px;
    // transform: rotate(-90deg);
    transition: transform 0.1s ease-in-out;
  }
}
.expand-list--mobile {
  & .mob-header__arrow {
    transform: rotate(-90deg);
  }
  & .mob-header__catgory-list {
    visibility: visible;
    opacity: 1;
    position: relative;
    transform: scale(1);
    transition: transform 0.15s ease-in, opacity 0.25s ease-in;
  }
}
.header--active {
  .mob-header {
    &__menu-btn {
      &--line-1 {
        transform: translateY(0.9rem) rotate(135deg);
      }
      &--line-2 {
        opacity: 0;
      }
      &--line-3 {
        transform: translateY(-0.9rem) rotate(-135deg);
      }
    }
  }
  & .mob-header__nav {
    transform: translateX(0);
  }
}
