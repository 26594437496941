.product-page {
  padding-top: 7.5rem;
  display: flex;
  @include change(tablet-small) {
    flex-direction: column;
    font-size: 2rem;
  }
}
.product-display {
  position: relative;
  padding: 2rem;
  flex: 1;
  &__img {
    position: sticky;
    max-width: 100%;
    width: 100%;
    // padding-top: 50px;
    top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include change(tablet-small) {
      position: relative;
      width: 100%;
      max-width: 100%;
      height: auto;
      padding-top: 0;
      top: 0;
    }
  }

  &__img img {
    width: 45rem;
    height: 45rem;
    max-width: 100%;
    object-fit: cover;
    // max-height: 90vh;
    // max-width: 100%;
    @include change(tablet-small) {
      // max-height: auto;
      max-width: 100%;
    }
  }
}

.product-content {
  flex: 1;
  padding: 6rem;
  @include change(mobile-regular) {
    padding: 2rem;
  }
}

/***** Content Area  ********/
.product-content {
  &__material {
    display: flex;
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    &::after {
      content: "";
      flex: 1;
      height: 0.05rem;
      width: 100%;
      background-color: black;
    }
  }
  &__title {
    font-size: 4rem;
    // margin-bottom: 1rem;
  }
  &__description {
    font-size: 1.8rem;
    color: var(--color-neutral-dark);
    margin-bottom: 1rem;
  }
}

.product-variant {
  &__options {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
    @include change(mobile-regular) {
      // max-height: 14rem;
      // gap: 1rem;
      // overflow: scroll;
      // box-shadow: 0px 0px 5px #00000050;
    }
  }
  &__info {
    margin-bottom: 0.5rem;
  }
  &__item {
    display: flex;
    @include change(mobile-regular) {
      // width: 100%;
      // flex: 0 0 100%;
    }
  }

  &__radio {
    display: none;
  }
  &__label {
    cursor: pointer;
    position: relative;
    padding: 1.25rem;
    z-index: 100;
    width: 100%;
    transition: all 0.25s ease-in;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      z-index: -1;
      width: 100%;
      background-color: var(--color-primary);
      transition: all 0.25s ease-in;
    }
  }
  &__radio:checked ~ &__label {
    color: var(--color-white);
    &::after {
      height: 100%;
    }
  }
  &__view-all {
    position: relative;
    display: flex;
    padding-bottom: 2px;
    margin-bottom: 2rem;
    font-size: 2rem;
    border: none;
    background-color: transparent;
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
  }
  &__view-all::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 0;
    height: 2px;
    background-color: currentColor;
    transition: all 0.25s ease-in;
  }
  &__view-all:hover {
    color: var(--color-secondary-1);
  }
  &__view-all:hover::after {
    width: 100%;
  }
}

.variant {
  &__info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 2rem;
    opacity: 0;
    transform: scale(0.95) translateY(10px);
    transform-origin: 0% 0% 0;
  }
  &__field {
    margin-bottom: 1rem;
  }
  &__field--50 {
  }
  &__field-label,
  &__field-text {
    display: block;
  }
  &__field-label {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    @include change(tablet-small) {
      font-size: 1.6rem;
    }
  }
  &__field-text {
    display: block;
    @include change(tablet-small) {
      font-size: 2.4rem;
    }
  }

  &__options {
    // padding: 20px 0px;
  }

  &__options-list {
    list-style: none;
    display: flex;
    gap: 1.2rem;
    flex-wrap: wrap;
  }

  &__options-item {
    padding: 5px 10px;
    // border-bottom: 1px solid;
    margin-top: 1rem;
    border: 2px solid var(--color-neutral-dark);
  }

  &__op-item-label {
    font-weight: 600;
  }
  &__op-item-currency {
    color: var(--color-secondary-1);
  }
  &__op-item-rates {
  }
}

.finishes__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem 0rem;
}

.finish {
  &__option {
    border: 1px solid #ebebeb;
    display: flex;
  }
  &__thumbnail {
    width: 6.4rem;
    max-width: 100%;
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0.5rem 1rem;
    line-height: 1;
  }
  &__label {
    font-size: 1.2rem;
  }
  &__rate {
    font-size: 2rem;
  }
}

.contact-options {
  position: fixed;
  display: flex;
  padding: 1.5rem;
  bottom: 0px;
  right: 0px;
  gap: 1rem;
  z-index: 10005;
  justify-content: flex-end;
  align-items: center;
  @include change(mobile-regular) {
    width: 100%;
    bottom: 0;
    right: 0;
  }
}

.send-inquiry-btn {
  border: 1px solid currentColor;
  font-size: 1.6rem;
  padding: 1.5rem 3rem;
  background-color: var(--color-black);
  color: var(--color-white);
  transition: all 0.25s ease-in;
}

.send-message-btn {
  height: 4.6rem;
  width: 4.6rem;
  border: 1px solid var(--color-black);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: all 0.25s ease-in;
}

.send-message-btn svg {
  fill: var(--color-black);
  max-height: 3rem;
  max-width: 3rem;
}

.send-message-btn:hover {
  border-radius: 10rem;
  background-color: var(--color-secondary-2);
  border-color: var(--color-secondary-2);
  & svg {
    fill: var(--color-white);
  }
}

.send-inquiry-btn:hover {
  background-color: var(--color-secondary-2);
  border-radius: 60px;
}
.send-inquiry {
  @include change(mobile-regular) {
    flex: 1;
  }
}

.dramatic-entry {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: all 0.15s ease-in;
}
