.broucher {
  &__main {
    max-width: 120rem;
    margin: auto;
    padding: 4rem;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  &__wrapper {
    display: flex;
    gap: 1rem;
  }
  &__card {
    transform: scale(0.9);
    transition: all 0.25s ease-in;
    // box-shadow: 2px 2px 10px #00000020;
    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 100%;
      filter: grayscale(1);
      transition: all 0.25s ease-in;
    }
  }
  &__card:hover,
  &__card:active {
    transform: scale(1);
    box-shadow: 0px 4px 8px -2px #00000043;
    & img {
      filter: grayscale(0);
    }
  }

  @include change(mobile-regular) {
    &__wrapper {
      flex-direction: column;
    }

    &__card {
      transform: scale(1);

      & img {
        filter: grayscale(0);
      }
    }
  }
}
