.popular-products {
  &__wrapper {
    display: flex;
    gap: 2rem;
    z-index: 1;
    @include change(mobile-regular) {
      flex-direction: column;
    }
  }

  &__card {
    position: relative;
    // background-image: url(/img/popular-product-card-background.png);
    background-image: radial-gradient(#e7e9ef 50%, #b6bdd0 100%);
    background-size: contain;
    overflow: hidden;
    border-radius: 0rem;
    box-shadow: 0 2px 15px 0 #00000030;
  }
  &__img {
    position: relative;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      display: block;
      width: 100%;
      height: auto;
      transition: var(--transition-ease-in);
      z-index: 1;
    }
    &::before {
      content: "";
      position: absolute;
      height: 70%;
      width: 70%;
      box-shadow: 0 0 30px 0 #00000040;
      border-radius: 50%;
      background-color: #fdfdfd;
      transition: all 0.2s ease-in;
      z-index: 0;
    }
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, #ffffff, transparent 50%);
    transition: var(--transition-ease-in);
    z-index: 5;
  }
  &__content {
    text-align: center;
    color: #000000;
    position: absolute;
    bottom: 2rem;
    left: 0;
    padding: 0 2rem;
    width: 100%;
    transition: var(--transition-ease-in);
    z-index: 6;
  }
  &__title {
    font-family: var(--family-display);
    font-size: 2.4rem;
    text-transform: uppercase;
    font-weight: 300;
    transition: var(--transition-ease-in);
  }
  &__button,
  &__button:link,
  &__button:visited {
    display: none;
    color: transparent;
    border: 2px solid currentColor;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding: 0 1rem;
    transition: var(--transition-ease-in);
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
  }

  &__card:hover {
    & .popular-products {
      &__card {
      }
      &__img {
        & img {
          transform: scale(1.08) translateY(-20px);
          z-index: 10;
        }
        &::before {
          content: "";
          transform: scale(1.08);
          box-shadow: 0 0 30px 0 #00000040;
        }
      }
      &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, #ffffff 20%, transparent 60%);
      }
      &__content {
      }
      &__title {
        // font-size: 2.6rem;
        transform: translateY(5px);
      }
      &__button,
      &__button:link,
      &__button:visited {
        padding: 1rem;
        height: fit-content;
        color: var(--color-primary);
      }
      &__button:hover {
        background-color: #11005a10;
      }
    }
  }
}
