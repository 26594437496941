@mixin change($breakpoint) {
  @if $breakpoint == desktop-small {
    @media only screen and (max-width: 62.5em) {
      @content;
    }
  }

  @if $breakpoint == tablet-small {
    @media only screen and (max-width: 48em) {
      @content;
    }
  }

  @if $breakpoint == mobile-regular {
    @media only screen and (max-width: 28.125em) {
      @content;
    }
  }
}
