.width-1200 {
  max-width: 114rem;
  margin: auto;
}

.width-1440 {
  max-width: 144rem;
  margin: auto;
}

.display-none {
  display: none !important;
}

.no-border-right {
  border-right: none !important;
}
.no-border-left {
  border-left: none !important;
}

.display-none--mobile {
  @include change(mobile-regular) {
    display: none !important;
  }
}

.padding-top--0 {
  padding-top: 0 !important;
}

.not-visible {
  opacity: 0;
  visibility: none;
  transition: all 0.25s ease-in-out;
}

.not-visible--mobile {
  @include change(mobile-regular) {
    opacity: 0;
    visibility: none;
    transition: all 0.25s ease-in-out;
  }
}
.link__container {
  text-decoration: none;
  color: unset;
}
