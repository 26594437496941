.product-list {
  &__content {
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
    gap: 2.5rem;
  }
}

.product-card {
  background-color: #f6f6f6;
  box-shadow: 0px 2px 5px #00000020;
  transition: all 0.25s ease-in;
  &__image {
    & img {
      padding-top: 2rem;
      display: block;
      max-width: 100%;
      transition: all 0.25s ease-in;
    }
  }
  &__info {
    padding: 3.5rem;
  }
  &__title {
    // font-family: var(--family-regular);
    font-size: 2.4rem;
    text-transform: capitalize;
    line-height: 1.2;
    margin-bottom: 1rem;
    // font-weight: bold;
  }
  &__feature {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--color-secondary-2);
    margin-bottom: 0.5rem;
  }
  &__button {
    font-size: 1.6rem;
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    color: currentColor;
    text-decoration: none;
    &::after {
      content: "";
      display: block;
      background-color: currentColor;
      width: 0;
      height: 2px;
      transition: width 0.25s ease-in;
    }
    // &:hover {
    //   color: var(--color-secondary-2);
    // }
    // &:hover::after {
    //   width: 100%;
    // }
  }
  &:hover {
    box-shadow: 0px 4px 15px #00000020;
    // transform: translateY(-0.5rem);
    .product-card {
      &__image img {
        transform: scale(1.1);
      }
      &__button {
        color: var(--color-secondary-2);
        &::after {
          width: 100%;
        }
      }
    }
  }
}
