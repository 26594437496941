.our-headquarter {
  &__wrapper {
    display: flex;

    box-shadow: 0 0 25px #00000050;
    border: 5px solid #ffffff;
    @include change(mobile-regular) {
      flex-direction: column;
    }
  }
  &__map {
    flex: 2;
    padding: 0rem;
    height: 70vh;
    & iframe {
      border: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__content {
    flex: 1;
    padding: 6rem;
    background-color: var(--color-light-1);
  }
  &__address {
    font-size: 1.85rem;
    font-weight: 200;
  }
  &__contact {
    padding-bottom: 6rem;
    & .heading-3 {
      font-family: var(--family-display);
      font-weight: 400;
      text-transform: uppercase;

      &::before {
        content: "";
        display: block;
        height: 0.8rem;
        width: 4rem;
        background-color: var(--color-secondary-2);
        border-radius: 10rem;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
    }
  }
  &__send-message,
  &__send-message:link {
    position: relative;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 1.5rem;
    text-align: center;
    color: var(--color-secondary-2);
    border: 2px solid currentColor;
    z-index: 1;
    transition: all 0.8s ease-in;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0 10rem 10rem 0;
      height: 100%;
      width: 0;
      background-color: var(--color-secondary-2);
      z-index: -1;
      transition: all 0.8s cubic-bezier(0.86, 0.06, 0.13, 0.99);
    }
  }
  &__send-message:hover,
  &__send-message:active {
    color: var(--color-white);
    border-color: transparent;
    &::before {
      width: 100%;
      border-radius: 0;
    }
  }
}

.social-icons {
  &__list {
    position: relative;
    list-style: none;
    display: flex;
    gap: 1rem;
    width: fit-content;
    max-width: 100%;
  }
  &__item {
    & a {
      height: 3.2rem;
      width: 3.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-secondary-2);
      border-radius: 2px;
      transition: all 0.25s ease-in;
      @include change(mobile-regular) {
        height: 4.5rem;
        width: 4.5rem;
      }
    }
  }
  &__icon {
    max-width: 100%;
    max-height: 100%;
    padding: 0.8rem;
    fill: #ffffff;
  }

  &__label {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 0.5rem;
    border-top: 1px solid var(--color-secondary-2);
    text-transform: uppercase;
    opacity: 0;
    visibility: hidden;
    transform: translateY(4rem) scaleY(0);
    transform-origin: 50% 0%;
    transition: all 0.25s ease-in;
  }

  &__item a:hover {
    border-radius: 50%;
    background-color: var(--color-primary);
    & ~ .social-icons__label {
      transform: translateY(4rem) scaleY(1);
      opacity: 1;
      visibility: visible;
    }
  }
}
