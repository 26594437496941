.simpleslider {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include change(small-tab) {
    width: 100%;
    gap: 0;
  }
}

.simpleslider__slide {
}

.btn--previous,
.btn--next {
  font-family: inherit;
  top: 50%;

  position: absolute;
  font-size: 2rem;
  border: none;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 0 #00000050;
  z-index: 1000;
  opacity: 0.8;
  &:active {
    opacity: 1;
  }
}

.btn--next {
  right: 0;
  transform: translateY(-50%) translateX(-50%);
  &:active {
    transform: translateY(-50%) translateX(-50%) scale(0.9);
  }
}
.btn--previous {
  left: 0;
  transform: translateY(-50%) translateX(50%);
  &:active {
    transform: translateY(-50%) translateX(50%) scale(0.9);
  }
}
.dots__container {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  width: 100%;
  padding: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  .dot {
    height: 1rem;
    width: 1rem;
    border-radius: 2rem;
    background-color: rgb(151, 151, 151);
    transition: var(--transition-ease-in);
    &--active {
      background-color: rgb(0, 0, 0);
      width: 3rem;
      //   transition: var(--transition-ease-in);
    }
  }
}
/// Site Specific Styles
