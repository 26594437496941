.list__content {
  padding: 4rem;
  padding-top: 8rem;
  @include change(tablet-small) {
    padding: 2rem;
    padding-top: 8rem;
  }
}

.list-page {
  &__sub-head {
    @include change(tablet-small) {
      font-size: 2.4rem;
      color: var(--color-secondary-1);
    }
  }
  &__main-head {
    @include change(tablet-small) {
      font-size: 5rem;
    }
  }
}
