.cover-template-1 {
  &__wrapper {
    padding: 12rem 4rem;
    & * {
      display: block;
    }
    @include change(tablet-small) {
      padding: 6rem 0;
    }
  }
  &__heading {
    font-size: 5rem;
    line-height: 1;
  }
  &__sub-head {
  }
  &__main-head {
    font-size: 8rem;
  }
}
