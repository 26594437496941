.contact-form {
  &__container {
    padding-top: 4rem;
    background-image: linear-gradient(
      to bottom,
      transparent 50%,
      var(--color-light-2) 50%
    );
  }
  &__wrapper {
    margin: auto;
    max-width: 80rem;
    background-color: #ffffff;
  }
}
