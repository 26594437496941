.text__list {
  padding: 40px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.to__copy {
  border: 1px solid;
  font-size: 24px;
  padding: 10px;
}

.copied {
  background-color: yellow;
}
