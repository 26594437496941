.main-content--home {
  max-width: 100%;
  overflow-x: hidden;
}
///Section-1

.hero__slider-wrapper {
  overflow: hidden;
}
.hero {
  &__slider {
    width: 100%;
    gap: 0;
  }
  &__slide {
    flex: 0 0 100%;
    height: 100vh;
    transition: all 0.3s ease-out;
    border: 0rem solid white;
    background-size: cover;
    position: relative;
  }
}
.hero-slide {
  &__container {
    position: absolute;
    bottom: 10%;
    width: 80rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  &__subheading {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: bold;
  }
}

@include change(tablet-small) {
  .hero__slide {
  }
  .hero-slide {
    &__container {
      width: 100%;
    }
  }
}

@include change(mobile-regular) {
  .hero__slide {
    height: 65vh;
    width: 100%;
  }
  .hero-slide {
    &__container {
      padding: 4rem;
    }
  }
}
.slide-1 {
  color: var(--color-white);
  background-image: linear-gradient(to top, #0a0253 10%, transparent 60%),
    url(/img/slider-image-handles.webp);
  background-position: top center;
}
.slide-2 {
  color: var(--color-white);
  background-image: linear-gradient(
      to top,
      #582f15 10%,
      #00000040 50%,
      transparent 90%
    ),
    url(/img/finishes-background-2.webp);
}
.slide-3 {
  color: var(--color-white);
  background-image: linear-gradient(
      to top,
      #081f5e 10%,
      #00000040 50%,
      transparent 90%
    ),
    url(/img/distribution-background.webp);
}
.slide-4 {
  color: var(--color-white);
  background-image: linear-gradient(
      to top,
      #6b0303 10%,
      #00000040 50%,
      transparent 90%
    ),
    url(/img/manufacturing-1.webp);
}

.hero-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

/// Section 2
.section__popular-products {
  & h2 {
    margin-bottom: 2rem;
  }
}
.section__popular-category {
  padding-top: 1rem !important;
}

///Section 3 Our Customers
.section__our-customers {
  padding: 0rem 6rem;
  @include change(tablet-small) {
    padding: 0rem 4rem;
  }
  @include change(mobile-regular) {
    padding: 0rem 2rem;
  }
}

.our-customers {
  &__container {
    display: flex;
    padding: 0;
    // box-shadow: 0 1px 20px 0 #00000050;
    min-height: 80vh;
    border-radius: 0rem;
    overflow: hidden;
    @include change(tablet-small) {
      min-height: auto;
    }
    @include change(mobile-regular) {
      flex-direction: column;
    }
  }
  &__col-1 {
    flex: 3;
    background-color: var(--color-light-2);
    @include change(mobile-regular) {
      // padding: 2rem 4rem;
    }
  }
  &__col-2 {
    flex: 2;
    background-color: var(--color-light-1);
  }
}
// Section 5 Our Branches

.section__our-branches {
  background-color: var(--color-light-2);
  & h2 {
    margin-bottom: 2rem;
  }
  @include change(mobile-regular) {
    padding: 6rem 4rem;
  }
}

.section__out-headquarter {
  background-color: var(--color-light-2);
  padding: 6rem;
  @include change(mobile-regular) {
    padding: 2rem;
  }
  & .heading-2 {
    margin-bottom: 1rem;
    &::before {
      content: "";
      display: block;
      height: 0.8rem;
      width: 4rem;
      border-radius: 10rem;
      margin-bottom: 0.5rem;
      background-color: var(--color-secondary-1);
    }
  }
}
