.related-products {
  max-width: 140rem;
  margin: auto;
  padding: 2rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.related-products__wrapper {
  display: flex;
  gap: 2rem;

  @include change(mobile-regular) {
    flex-direction: column;
  }
}
