.product-category {
  &__wrapper {
    display: flex;
    gap: 2rem;
  }
  &__card {
    background-color: var(--color-light-2);
    flex: 1;
    padding: 2rem;
    transform: translateY(1rem) scale(0.95);
    transition: all 0.35s, transform 0.35s ease-in;
  }
  &__card:hover,
  &__card:active {
    background-color: var(--color-neutral-dark);
    color: var(--color-white);
    transform: translateY(0) scale(1);
  }
  &__card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    justify-content: space-between;
  }
  &__icon {
    max-width: 100%;
    transform: translateY(-20%) scale(1.3);
    transition: all 0.75s cubic-bezier(0.69, -0.12, 0.37, 1.05);
  }
  &__card:active &__icon,
  &__card:hover &__icon {
    transform: translateY(0) scale(1);
  }
  &__decorative {
    background-color: var(--color-secondary-1);
    border-radius: 50rem;
    display: block;
    height: 1rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
    transition: all 0.75s cubic-bezier(0.65, -0.73, 0.29, 1.56);
    width: 3rem;
  }
  &__card:active &__decorative,
  &__card:hover &__decorative {
    width: 7rem;
  }
  &__button,
  &__button:link,
  &__button:visited {
    border: 1px solid;
    color: var(--color-primary);
    display: block;
    font-size: inherit;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }
  &__card:active &__button,
  &__card:active &__button:link,
  &__card:active &__button:visited,
  &__card:hover &__button,
  &__card:hover &__button:link,
  &__card:hover &__button:visited {
    color: var(--color-white);
  }
}

@include change(mobile-regular) {
  .product-category {
    &__wrapper {
      flex-direction: column;
      gap: 4rem;
    }
  }
}
