.inquiry-form__container {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  display: flex;
  flex-direction: column;
  height: 85vh;
  width: 45%;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px 0 #00000020;
  transition: all 0.3s ease-in-out;
  z-index: 10000;
  @include change(tablet-small) {
    padding: 8rem 4rem;
    width: 93%;
    justify-content: flex-start;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    bottom: 15vh;
    height: 70vh;
    overflow: scroll;
  }
  @include change(mobile-regular) {
    padding: 2rem;
    height: auto;
    bottom: 5vh;
    max-height: 80vh;
    box-shadow: 0px -6px 10px 0 #00000020;
  }
}
.inquiry-form__collaps {
  bottom: 2rem;
  left: 90%;
  height: 2rem;
  width: 1rem;
  opacity: 0;
  @include change(mobile-regular) {
    width: 90%;
    left: 50%;
  }
}

.close-inquiry-form {
  position: absolute;
  cursor: pointer;
  right: 2rem;
  top: 2rem;
  height: 4rem;
  width: 4rem;
  color: var(--color-primary);
  border: 1px solid currentColor;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in;

  &:hover {
    border-radius: 20rem;
    color: var(--color-white);
    border-color: var(--color-secondary-1);
    background-color: var(--color-secondary-1);
  }
  &:active {
    transform: scale(0.95);
  }
}

.form-1 {
  padding: 4rem;
  width: 100%;
  &__field--half {
    display: inline-block;
    width: 46%;
    margin-bottom: 2rem;
    margin-right: 3%;
    @include change(mobile-regular) {
      width: 100%;
    }
  }
  &__field--full {
    display: block;
    margin-bottom: 2rem;
    margin-right: 3%;
  }
  &__label {
    display: block;
  }
  &__text,
  &__dropdown {
    display: block;
    padding: 1rem;
    width: 100%;
    border: none;
    border-bottom: 2px solid;
    // box-shadow: 2px 2px 5px 0 #00000020;
  }
  &__text:focus,
  &__dropdown:focus {
    border: none;
    border-bottom: 2px solid var(--color-secondary-2);
    outline: none;
  }
  & textarea {
    font-family: var(--family-regular);
    line-height: 1.5;
  }
  &__btn {
    position: relative;
    color: #ffffff;
    background-color: var(--color-primary);
    border: none;
    padding: 1.5rem;
    width: 100%;
    transition: all 0.25s ease-in;
    &:hover {
      background-color: var(--color-secondary-2);
    }
  }
}

.h-captcha {
  max-width: 100%;
  & iframe {
    max-width: 100%;
    @include change(mobile-regular) {
      width: 100%;
    }
  }
}

#view__result {
  margin-top: 1rem;
  padding: 1rem 4rem;
  display: none;
}

.form-success {
  display: block !important;
  background-color: #c1f0bd;
}

.form-fail {
  display: block !important;
  background-color: #f5cbcb;
}

.form-caution {
  display: block !important;
  background-color: #f3f3c5;
}
