.customer-reviews__wrapper {
  display: flex;
  min-height: 100%;
}

.customer-review {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 100%;
  transition: var(--transition-plain);

  &__content-wrapper {
    max-width: 60%;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    @include change(mobile-regular) {
      max-width: 100%;
      padding: 6rem;
    }
  }
  &__message {
    position: relative;
    font-size: 2rem;
    font-weight: 300;
    // text-align: center;
    &::before,
    &::after {
      content: '"';
      position: absolute;
      font-size: 8rem;
    }
    &::before {
      top: -7.5rem;
      left: -2rem;
    }
    &::after {
      bottom: -7.5rem;
      right: -2rem;
    }
  }
  &__customer {
    &::before {
      content: "";
      display: block;
      position: relative;
      height: 0.8rem;
      width: 3rem;
      margin-bottom: 0.5rem;
      border-radius: 5rem;
      background-color: var(--color-secondary-1);
    }
  }
  &__name {
    font-family: var(--family-display);
    font-size: 1.8rem;
  }
  &__about {
    font-size: 1.2rem;
  }
}
