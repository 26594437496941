:root {
  //colors

  --color-primary: #0a2463;
  --color-light-1: #b6bdd0;
  --color-light-2: #e7e9ef;
  --color-dark-1: #081d4f;

  --color-secondary-1: #fb3640;
  --color-secondary-2: #247ba0;
  --color-secondary-3: #605f5e;

  --color-black: #020714;
  --color-neutral-dark: #484848;
  --color-neutral-grey-dark: #868483;
  --color-neutral-grey-light: #d2cfcd;
  --color-white: #f8f4f2;

  //Font Size
  --font-regular: 1.4rem;
  --font-large: 1.6rem;
  --line-height-regular: 1.5;
  --heading-one: 3.2rem;
  --heading-two: 2.4rem;
  --heading-three: 2.1rem;
  --family-regular: "Lexend", sans-serif;
  --family-display: "Fjalla One", sans-serif;

  // default transtion
  --transition-plain: all 0.2s;
  --transition-ease-in: all 0.3s ease-in;
  @include change(tablet-small) {
    --font-regular: 1.6rem;
    --font-large: 1.8rem;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;

  @include change(desktop-small) {
    font-size: 56.25%;
  }
}

main {
  padding-top: 8rem;
}

body {
  font-family: var(--family-regular);
  font-size: var(--font-regular);
  font-weight: 400;
  line-height: var(--line-height-regular);
  color: var(--color-black);

  //   &::-webkit-scrollbar {
  //     width: 1.2rem;
  //   }

  //   &::-webkit-scrollbar-track {
  //     background-color: var(--color-primary-light-3);
  //   }
  //   &::-webkit-scrollbar-thumb {
  //     background-color: var(--color-primary-light-2);
  //     // border: 0.1rem solid var(--color-primary-light-3);
  //   }
}
h1,
h2 {
  font-family: var(--family-display);
  font-weight: normal;
  text-transform: uppercase;
}

.poppins--font {
  font-family: "poppins", sans-serif;
}

.heading-1 {
  font-size: 4rem;
}
.heading-2 {
  font-size: var(--heading-two);
}
.heading-3 {
  font-size: var(--heading-three);
}
.sub-heading {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
}

button,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.section--padding {
  padding: 10rem 6rem;
  @include change(tablet-small) {
    padding: 6rem 2rem;
  }
}

.invisible {
  opacity: 0;
  visibility: hidden;
}

.hidden {
  display: none !important;
}

.decorative-line {
  height: 1.2rem;
  width: 6rem;
  background-color: white;
  border-radius: 2rem;
  display: block;
  margin: auto;
  margin-bottom: 1rem;
}
