.categories__content {
  padding: 4rem;
  padding-top: 8rem;
  // padding-bottom: 10rem;
  @include change(tablet-small) {
    padding: 2rem;
    padding-top: 8rem;
  }
}

.category {
  &__body {
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
    gap: 2rem;
  }
}

.category-card {
  background-color: #f6f6f6;
  box-shadow: 0 3px 6px 1px #00000020;
  &__image {
    // background-color: #ffffff;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 5/4;
    & img {
      width: 100%;
      height: 25rem;
      object-fit: cover;
    }
  }
  &__info {
    padding: 0.5rem 4rem 4rem 4rem;
  }
  &__title {
    font-size: 2.4rem;
  }
  &__view-more {
    display: inline-block;
    position: relative;
    font-size: 1.8rem;
    text-decoration: none;
    color: var(--color-secondary-1);
    &::after {
      content: "";
      position: absolute;
      background-color: currentColor;
      height: 0.2rem;
      width: 0;
      left: 0;
      bottom: 0;
      transition: width 0.25s ease-in;
    }
  }
  &:hover .category-card__view-more {
    color: var(--color-secondary-2);
    &::after {
      width: 100%;
    }
  }
  & a {
    text-decoration: none;
    color: var(--color-black);
  }
}

.category-page {
  &__sub-head {
    @include change(tablet-small) {
      font-size: 2.4rem;
      font-weight: 700;
      color: var(--color-secondary-2);
      display: block;
      margin-bottom: 1rem;
    }
  }
  &__main-head {
    @include change(tablet-small) {
      font-size: 5rem;
      display: block;
    }
  }
}
